<template>
  <div>
    <div v-if="!showCheckout">
      <table class="limit_table text-left w-full">
        <thead class="text-[#757A8A] font-semibold text-[0.875rem] font-poppins bg-[#F4F6FA]">
        <tr>
          <td class="py-3 pl-3 w-36">Item</td>
          <td class="py-3 text-left w-32">Price</td>
          <td class="py-3 pl-3 w-36">Quantity</td>
          <td class="py-3 pr-3 text-right w-20">Amount</td>
        </tr>
        </thead>
        <tbody class="text-[#3C4549] text-[0.875rem] font-poppins">
        <tr class="border-b border-[#ECEEF5] px-3">
          <td class="py-[1.2rem] pl-3 w-36">Workspaces</td>
          <td class="py-[1.2rem] text-left w-32">${{ addon_price.monthly.brands.price }}/month</td>
          <td class="py-[1.2rem] pl-3 w-36">
            <input class="w-20 h-8 rounded text !text-[#3C4549]" min="0" v-model="addon_price.monthly.brands.quantity" type="number">
          </td>
          <td class="py-[1.2rem] pr-4 text-right w-20">${{ addon_price.monthly.brands.price * addon_price.monthly.brands.quantity }}</td>
        </tr>
        <tr class="border-b border-[#ECEEF5] px-3">
          <td class="py-[1.2rem] pl-3 w-36">Campaigns</td>
          <td class="py-[1.2rem] text-left w-32">${{ addon_price.monthly.campaigns.price }}/month</td>
          <td class="py-[1.2rem] pl-3 w-36">
            <input class="w-20 h-8 rounded text !text-[#3C4549]" min="0" v-model="addon_price.monthly.campaigns.quantity" type="number">
          </td>
          <td class="py-[1.2rem] pr-4 text-right w-20">${{ parseCampaignPrice() }}</td>
        </tr>
        <tr class="border-b border-[#ECEEF5] px-3">
          <td class="py-[1.2rem] pl-3 w-36">Clicks</td>
          <td class="py-[1.2rem] text-left w-32">${{ addon_price.monthly.clicks.price }}/month</td>
          <td class="py-[1.2rem] pl-3 w-36">
            <input class="w-20 h-8 rounded text !text-[#3C4549]" min="0" v-model="addon_price.monthly.clicks.quantity" type="number">
          </td>
          <td class="py-[1.2rem] pr-4 text-right w-20">${{ parseClicksPrice() }}</td>
        </tr>
        <tr class="border-b border-[#ECEEF5] px-3">
          <td class="py-[1.2rem] pl-3 w-36">Retargeting Pixels</td>
          <td class="py-[1.2rem] text-left w-32">${{ addon_price.monthly.retargeting_pixels.price }}/month</td>
          <td class="py-[1.2rem] pl-3 w-36">
            <input class="w-20 h-8 rounded text !text-[#3C4549]" min="0" v-model="addon_price.monthly.retargeting_pixels.quantity" type="number">
          </td>
          <td class="py-[1.2rem] pr-4 text-right w-20">${{addon_price.monthly.retargeting_pixels.price * addon_price.monthly.retargeting_pixels.quantity }}</td>
        </tr>
        <tr class="border-b border-[#ECEEF5] px-3">
          <td class="py-[1.2rem] pl-3 w-36">Custom Domains</td>
          <td class="py-[1.2rem] text-left w-32">${{addon_price.monthly.custom_domains.price * addon_price.monthly.custom_domains.quantity }}/month</td>
          <td class="py-[1.2rem] pl-3 w-36">
            <input class="w-20 h-8 rounded text !text-[#3C4549]" min="0" v-model="addon_price.monthly.custom_domains.quantity" type="number">
          </td>
          <td class="py-[1.2rem] pr-4 text-right w-20">${{addon_price.monthly.custom_domains.price * addon_price.monthly.custom_domains.quantity }}</td>
        </tr>
        <tr class="border-b border-[#ECEEF5] px-3">
          <td class="py-[1.2rem] pl-3 w-36">Team Members</td>
          <td class="py-[1.2rem] text-left w-32">${{addon_price.monthly.team_members.price * addon_price.monthly.team_members.quantity }}/month</td>
          <td class="py-[1.2rem] pl-3 w-36">
            <input class="w-20 h-8 rounded text !text-[#3C4549]" min="0" v-model="addon_price.monthly.team_members.quantity" type="number">
          </td>
          <td class="py-[1.2rem] pr-4 text-right w-20">${{addon_price.monthly.team_members.price * addon_price.monthly.team_members.quantity }}</td>
        </tr>
        <tr class="border-b border-[#ECEEF5] px-3">
          <td class="py-[1.2rem] pl-3 w-36">Bio-links</td>
          <td class="py-[1.2rem] text-left w-32">${{addon_price.monthly.bio_links.price * addon_price.monthly.bio_links.quantity }}/month</td>
          <td class="py-[1.2rem] pl-3 w-36">
            <input class="w-20 h-8 rounded text !text-[#3C4549]" min="0" v-model="addon_price.monthly.bio_links.quantity" type="number">
          </td>
          <td class="py-[1.2rem] pr-4 text-right w-20">${{addon_price.monthly.bio_links.price * addon_price.monthly.bio_links.quantity }}</td>
        </tr>
        </tbody>
        </table>
        <div class="flex px-3 justify-between rounded items-center bg-[#F7F7FC] h-[3.75rem] py-[1.5rem]">
          <div class="flex gap-x-3 items-center">
            <p class="text !text-[#202324] !text-[0.875rem] font-bold">Bill Type:</p>
            <div :class="{'opacity-40': disableBillType('annually')}" class="flex items-center gap-x-2.5 ">
              <input
                class="relative float-left h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] bg-[#ECEEF5] outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0  before:content-[''] checked:bg-[#0165E1] checked:before:opacity-[0.16] checked:after:absolute checked:after:mt-4 checked:after:ml-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] focus:before:scale-100 focus:before:opacity-[0.12] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:after:mt-[0.2rem] checked:focus:after:ml-[0.40rem] checked:focus:after:h-[0.6rem] checked:focus:after:w-[0.3rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0   checked:focus:after:bg-transparent  checked:before:scale-100 checked:after:mt-[0.2rem] checked:after:ml-[0.40rem] checked:after:h-[0.6rem] checked:after:w-[0.3rem] checked:after:rotate-45 checked:after:rounded-none checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0   checked:after:bg-transparent"
                type="radio"
                v-model="bill_type"
                :disabled="disableBillType('annually')"
                value="monthly"
                id="monthly" />
              <label class="inline-block text-[0.875rem] font-poppins mb-0 hover:cursor-pointer" for="monthly">Monthly</label>
            </div>

            <div :class="{'opacity-40': disableBillType('monthly')}" class="flex items-center gap-x-2.5 ">
              <input
                class="relative float-left h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] bg-[#ECEEF5] outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0  before:content-[''] checked:bg-[#0165E1] checked:before:opacity-[0.16] checked:after:absolute checked:after:mt-4 checked:after:ml-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] focus:before:scale-100 focus:before:opacity-[0.12] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:after:mt-[0.2rem] checked:focus:after:ml-[0.40rem] checked:focus:after:h-[0.6rem] checked:focus:after:w-[0.3rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0   checked:focus:after:bg-transparent  checked:before:scale-100 checked:after:mt-[0.2rem] checked:after:ml-[0.40rem] checked:after:h-[0.6rem] checked:after:w-[0.3rem] checked:after:rotate-45 checked:after:rounded-none checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0   checked:after:bg-transparent"
                type="radio"
                v-model="bill_type"
                value="annually"
                :disabled="disableBillType('monthly')"
                id="annually" />
              <label class="inline-block text-[0.875rem] font-poppins mb-0 hover:cursor-pointer" for="annually">Annually</label>
            </div>

          </div>
          <p class="text flex gap-x-1 items-center !text-[#3C4549] text-semibold">Grand Total (Annually):
            <span class="font-bold">${{ getAddonsPrice }}</span>
            <svg v-if="bill_type === 'monthly' && getAddonsPrice < 10" v-tooltip="'Monthly billing is only available for orders greater than $10/month.'" xmlns="http://www.w3.org/2000/svg" class="cursor-pointer w-[1rem] h-[0.9rem]" viewBox="0 0 17 18" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.53298 2.47656C4.93058 2.47656 2.01025 5.39689 2.01025 8.99929C2.01025 12.6017 4.93058 15.522 8.53298 15.522C12.1354 15.522 15.0557 12.6017 15.0557 8.99929C15.0557 5.39689 12.1354 2.47656 8.53298 2.47656ZM0.510254 8.99929C0.510254 4.56846 4.10215 0.976562 8.53298 0.976562C12.9638 0.976562 16.5557 4.56846 16.5557 8.99929C16.5557 13.4301 12.9638 17.022 8.53298 17.022C4.10215 17.022 0.510254 13.4301 0.510254 8.99929ZM8.53298 5.3402C8.94719 5.3402 9.28298 5.67598 9.28298 6.0902V8.99929C9.28298 9.4135 8.94719 9.74929 8.53298 9.74929C8.11877 9.74929 7.78298 9.4135 7.78298 8.99929V6.0902C7.78298 5.67598 8.11877 5.3402 8.53298 5.3402ZM8.53298 11.1584C8.11877 11.1584 7.78298 11.4942 7.78298 11.9084C7.78298 12.3226 8.11877 12.6584 8.53298 12.6584H8.54025C8.95447 12.6584 9.29025 12.3226 9.29025 11.9084C9.29025 11.4942 8.95447 11.1584 8.54025 11.1584H8.53298Z" fill="#F4B740"/>
            </svg>
          </p>
        </div>
      <div class="pt-10 mb-10 flex gap-x-3 justify-center items-center">
        <Button
          id="cancel-button"
          type="button"
          class="border border-gray-500 hover:bg-[#F2F3F8]"
          buttonClass="btn-lg"
          @click="close()"
        >
          <template v-slot:label>Cancel</template>
        </Button>
        <Button
          id="purchase-button"
          type="button"
          class="text-white bg-[#2560D7] hover:bg-blue-700 "
          buttonClass="btn-lg"
          @click="purchaseAddonAction()"
          :disabled="(bill_type === 'monthly' && getAddonsPrice < 10) || isIncreasingLimitsLoader || getAddonsPrice <= 0"
        >
          <template v-slot:label>{{ getEditModifierLimits && getEditModifierLimits._id ? 'Update Modifier' : 'Purchase' }}</template>
          <template v-if="purchaseLoader" v-slot:loader>
            <Loader></Loader>
          </template>
        </Button>
      </div>
    </div>
    <!--    <template v-else>-->
    <a v-if="showCheckout" @click="showCheckout = !showCheckout; purchaseLoader = !purchaseLoader" style="cursor: pointer" class="text">
      <i class="fal fa-chevron-left mr-1"></i>Back
    </a>

    <Loader v-if="showCheckout && loaders.increase_limits" color="text-[#2560D7] w-[2rem] !h-[2rem]"></Loader>

    <div v-if="showCheckout" class="h-full w-full my-2 flex items-center justify-center checkout-container" id="checkout-container">
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import http from '@/mixins/http-lib'
import {purchaseAddonModifier, getOverridePriceURL, deleteAddonModifier} from '@/config/api'
import EventBus from '@/state/event-bus';
import {paddle_ids} from "@/mixins/pricing_constants";

export default ({
  name: 'individual-limits-component',
  props: [
    'profile',
    'subscription'
  ],
  components: {
    Checkbox: () => import('@/ui/ui-kit/v2/Checkbox.vue'),
    Button: () => import('@/ui/ui-kit/v2/Button.vue'),
    Loader: () => import('@/ui/ui-kit/v2/Loader.vue'),
  },
  data () {
    return {
      paddle_ids: paddle_ids,
      purchaseLoader: false,
      showCheckout: false,
      passthrough: '',
      loaders: {
        increase_limits: false,
        paddle: true
      },
      modalId: 'increase-limits-dialog',
      bill_type: 'annually',
      addon_price: {
        monthly: {
          brands: {
            quantity: 1,
            price: 1,
            prev: 1
          },
          campaigns: {
            quantity: 3,
            min: 3,
            price: 1,
            prev: 3
          },
          retargeting_pixels: {
            quantity: 1,
            price: 1,
            prev: 1
          },
          custom_domains: {
            quantity: 1,
            price: 2,
            prev: 1
          },
          team_members: {
            quantity: 1,
            price: 2,
            prev: 1
          },
          bio_links: {
            quantity: 1,
            price: 1,
            prev: 1
          },
          clicks: {
            quantity: 3000,
            min: 3000,
            price: 1,
            prev: 3000
          },
          branding: {
            value: 'yes',
            price: 5,
            base_price: 5
          }
        }
      }
    }
  },
  created() {
    if(this.isActiveAddonSubscription) {
      this.bill_type = this.getActiveAddonDetail.bill_type
    }

    if(this.getEditModifierLimits) {
      this.addon_price = {
        monthly: {
          brands: {
            quantity: this.getEditModifierLimits.addons['brands'],
            price: 1,
            prev: this.getEditModifierLimits.addons['brands']
          },
          campaigns: {
            quantity: this.getEditModifierLimits.addons['campaigns'],
            min: 3,
            price: 1,
            prev: this.getEditModifierLimits.addons['campaigns']
          },
          retargeting_pixels: {
            quantity: this.getEditModifierLimits.addons['retargeting_pixels'],
            price: 1,
            prev: this.getEditModifierLimits.addons['retargeting_pixels']
          },
          custom_domains: {
            quantity: this.getEditModifierLimits.addons['custom_domains'],
            price: 2,
            prev: this.getEditModifierLimits.addons['custom_domains']
          },
          team_members: {
            quantity: this.getEditModifierLimits.addons['team_members'],
            price: 2,
            prev: this.getEditModifierLimits.addons['team_members'],
          },
          bio_links: {
            quantity: this.getEditModifierLimits.addons['bio_links'],
            price: 1,
            prev: this.getEditModifierLimits.addons['bio_links']
          },
          clicks: {
            quantity: this.getEditModifierLimits.addons['clicks'],
            min: 3000,
            price: 1,
            prev: this.getEditModifierLimits.addons['clicks']
          },
          branding: {
            value: 'yes',
            price: 5,
            base_price: 5
          }
        }
      }
    }

  },
  computed: {
    ...mapGetters(['getProfile', 'getActiveAddonDetail', 'getEditModifierLimits']),
    getAddonsPrice () {
      let sumAmount =
        (this.addon_price.monthly.brands.price * this.addon_price.monthly.brands.quantity) +
        (this.parseCampaignPrice()) +
        (this.parseClicksPrice()) +
        (this.addon_price.monthly.retargeting_pixels.price * this.addon_price.monthly.retargeting_pixels.quantity) +
        (this.addon_price.monthly.custom_domains.price * this.addon_price.monthly.custom_domains.quantity) +
        (this.addon_price.monthly.team_members.price * this.addon_price.monthly.team_members.quantity) +
        (this.addon_price.monthly.bio_links.price * this.addon_price.monthly.bio_links.quantity)
      if (!this.subscription.limits.branding) sumAmount += this.addon_price.monthly.branding.price
      if (this.bill_type === 'annually') return sumAmount * 12

      return sumAmount
    },
    isIncreasingLimitsLoader () {
      return this.loaders.increase_limits
    },
    isActiveAddonSubscription() {
      return this.getActiveAddonDetail && this.getActiveAddonDetail.paddle_subscription_id
    }
  },
  methods: {
    /**
     * check if paddle addon subscription bill type monthly or yearly then we will disable the bill type accordingly
     * @param billType
     * @returns {boolean}
     */
    disableBillType(billType = '') {
      if(!this.isActiveAddonSubscription) {
        return  false
      }
      return this.getActiveAddonDetail.bill_type === billType
    },
    /**
     * Getting the Addons plan id on the bases of ENV
     * @param type
     * @returns {*}
     */
    getAddonPaddlePlanId(type = '') {
      return paddle_ids[process.env.VUE_APP_ENVIRONMENT][type]
    },
    purchaseAddonAction () {
      let self = this

      if(this.purchaseLoader) {
        return
      }

      this.purchaseLoader = true

      // If in case we will delete first modifier and create new modifier
      if(this.getEditModifierLimits && this.getEditModifierLimits._id) {
        this.deleteAddonModifier(this.getEditModifierLimits)
      }

      // if Addons are already purchased so we directly buy the new addon without opening paddle popup
      this.showCheckout = !this.isActiveAddonSubscription

      setTimeout(() => {
        let productId = (this.bill_type === 'monthly') ? this.getAddonPaddlePlanId('replug_addons_monthly') : this.getAddonPaddlePlanId('replug_addons_annually')
        let branding = false

        if (this.addon_price.monthly.branding.value === 'yes') branding = true
        this.passthrough = {
          brands: parseInt(this.addon_price.monthly.brands.quantity),
          campaigns: parseInt(this.addon_price.monthly.campaigns.quantity),
          clicks: parseInt(this.addon_price.monthly.clicks.quantity),
          retargeting_pixels: parseInt(this.addon_price.monthly.retargeting_pixels.quantity),
          custom_domains: parseInt(this.addon_price.monthly.custom_domains.quantity),
          team_members: parseInt(this.addon_price.monthly.team_members.quantity),
          bio_links: parseInt(this.addon_price.monthly.bio_links.quantity),
          branding: branding,
          bill_type: this.isActiveAddonSubscription ? this.getActiveAddonDetail.bill_type : this.bill_type
        }

        // if user has purchased the addons then we will add new addon as modifier
        if(this.isActiveAddonSubscription) {
          this.purchaseLoader = true
          http.post(purchaseAddonModifier, {
            modifier_recurring: true,
            subscription_id: this.getActiveAddonDetail.paddle_subscription_id,
            modifier_amount: this.getAddonsPrice,
            modifier_description: JSON.stringify(this.passthrough)
          }).then(
            res => {
              this.purchaseLoader = false
              this.$store.dispatch('toastNotification', {
                message: res.data.message,
                type: 'success'
              })
              location.reload()
            }
          )
          return
        }

        http.post(getOverridePriceURL, {
          paddle_id: productId,
          price: this.getAddonsPrice
        }).then(
          resp => {

            var paddleOptions = {
              method: 'inline',
              product: productId,
              email: this.profile.email,
              title: '',

              price: this.getAddonsPrice,
              passthrough: this.passthrough,
              allowQuantity: false,
              disableLogout: true,
              frameTarget: 'checkout-container', // The className of your checkout <div>
              frameInitialHeight: 416,
              frameStyle: 'width:100%; min-width:495px; background-color: transparent; border: none;',    // Please ensure the minimum width is kept at or above 495px
              successCallback: function (data) {
                self.upgradeCallback(data)
              }
            }

            paddleOptions.auth = resp.data.token
            // check if add on is monthly then set the recurring price
            // also passing the data payload.
            paddleOptions.recurringPrices = [
              {
                currency: 'USD',
                price: this.getAddonsPrice,
                auth: resp.data.token,
                quantity: 1
              }
            ]

            Paddle.Checkout.open(paddleOptions)
          }
        ).catch()
      }, 500)

    },
    upgradeCallback (data) {
      location.reload()
      this.loaders.increase_limits = true
      this.$emit('update', data)
    },
    parseCampaignPrice () {
      const price = (this.addon_price.monthly.campaigns.quantity / this.addon_price.monthly.campaigns.min) *
        this.addon_price.monthly.campaigns.price
      if (!Number.isInteger(price)) {
        return Number(Math.round(price + 'e' + 2) + 'e-' + 2)
      }
      return price
    },
    parseClicksPrice () {
      const price = (this.addon_price.monthly.clicks.quantity / this.addon_price.monthly.clicks.min) *
        this.addon_price.monthly.clicks.price
      if (!Number.isInteger(price)) {
        return Number(Math.round(price + 'e' + 2) + 'e-' + 2)
      }
      return price
    },
    updateBrandingPrice () {
      if (this.subscription.limits.branding) {
        this.addon_price.monthly.branding.price = 0
        this.addon_price.monthly.branding.value = 'no'
      }
      else if (this.addon_price.monthly.branding.value) {
        this.addon_price.monthly.branding.price = parseInt(this.addon_price.monthly.branding.base_price)
      } else {
        this.addon_price.monthly.branding.price = 0
      }
    },
    isInteger( str ) {
      const regex = new RegExp(/^[0-9]{1,8}$/);
      return regex.test( str );
    },
    close () {
      EventBus.$emit('close_addon_modal', true);
    },
    /**
     * We are deleting the subscription modifier on the bases of modifier_id & paddle_subscription_id
     * @param item
     */
    deleteAddonModifier(item) {
      http.post(deleteAddonModifier, {
        'modifier_id': item.modifier_id,
        'subscription_id': item.paddle_subscription_id
      }).then(res => {
        if (res.data.status) {}
      }).catch(err => {
        return err
      })
    }
  },
  watch: {
    'addon_price.monthly.brands.quantity' (value) {
      if(!this.isInteger(value)){
        this.addon_price.monthly.brands.quantity = this.addon_price.monthly.brands.prev
      }

      if (value < 0) {
        this.addon_price.monthly.brands.quantity = 0
      } else if (value > 1000) {
        this.addon_price.monthly.brands.quantity = 1000
      }
      this.addon_price.monthly.brands.prev = this.addon_price.monthly.brands.quantity

    },
    'addon_price.monthly.campaigns.quantity' (value) {
      if(!this.isInteger(value)){
        this.addon_price.monthly.campaigns.quantity = this.addon_price.monthly.campaigns.prev
        return
      }
      if (value < 0) {
        this.addon_price.monthly.campaigns.quantity = 0
      } else if (value > 1000) {
        this.addon_price.monthly.campaigns.quantity = 1000
      } else {
        if (value > this.addon_price.monthly.campaigns.prev) {
          this.addon_price.monthly.campaigns.quantity = Math.ceil((value / this.addon_price.monthly.campaigns.min)) * this.addon_price.monthly.campaigns.min
        } else {
          this.addon_price.monthly.campaigns.quantity = Math.floor((value / this.addon_price.monthly.campaigns.min)) * this.addon_price.monthly.campaigns.min
        }
        this.addon_price.monthly.campaigns.prev = this.addon_price.monthly.campaigns.quantity
      }
    },
    'addon_price.monthly.retargeting_pixels.quantity' (value) {
      if(!this.isInteger(value)){
        this.addon_price.monthly.retargeting_pixels.quantity = this.addon_price.monthly.retargeting_pixels.prev
      }
      if (value < 0) {
        this.addon_price.monthly.retargeting_pixels.quantity = 0
      } else if (value > 1000) {
        this.addon_price.monthly.retargeting_pixels.quantity = 1000
      }
      this.addon_price.monthly.retargeting_pixels.prev = this.addon_price.monthly.retargeting_pixels.quantity

    },
    'addon_price.monthly.custom_domains.quantity' (value) {
      if(!this.isInteger(value)){
        this.addon_price.monthly.custom_domains.quantity = this.addon_price.monthly.custom_domains.prev
      }
      if (value < 0) {
        this.addon_price.monthly.custom_domains.quantity = 0
      } else if (value > 1000) {
        this.addon_price.monthly.custom_domains.quantity = 1000
      }
      this.addon_price.monthly.custom_domains.prev = this.addon_price.monthly.custom_domains.quantity

    },
    'addon_price.monthly.team_members.quantity' (value) {
      if(!this.isInteger(value)){
        this.addon_price.monthly.team_members.quantity = this.addon_price.monthly.team_members.prev
      }
      if (value < 0) {
        this.addon_price.monthly.team_members.quantity = 0
      } else if (value > 1000) {
        this.addon_price.monthly.team_members.quantity = 1000
      }
      this.addon_price.monthly.team_members.prev = this.addon_price.monthly.team_members.quantity

    },
    'addon_price.monthly.bio_links.quantity' (value) {
      if(!this.isInteger(value)){
        this.addon_price.monthly.bio_links.quantity = this.addon_price.monthly.bio_links.prev
      }
      if (value < 0) {
        this.addon_price.monthly.bio_links.quantity = 0
      } else if (value > 1000) {
        this.addon_price.monthly.bio_links.quantity = 1000
      }
      this.addon_price.monthly.bio_links.prev = this.addon_price.monthly.bio_links.quantity
    },
    'addon_price.monthly.clicks.quantity' (value) {
      if(!this.isInteger(value)){
        this.addon_price.monthly.clicks.quantity = this.addon_price.monthly.clicks.prev
        return
      }
      if (value < 0) {
        this.addon_price.monthly.clicks.quantity = 0
        this.addon_price.monthly.clicks.prev = this.addon_price.monthly.clicks.quantity
      } else if (value > 10000000) {
        this.addon_price.monthly.clicks.quantity = 10000000
        this.addon_price.monthly.clicks.prev = this.addon_price.monthly.clicks.quantity
      } else {
        if (value > this.addon_price.monthly.clicks.prev) {
          this.addon_price.monthly.clicks.quantity = Math.ceil((value / this.addon_price.monthly.clicks.min)) * this.addon_price.monthly.clicks.min
        } else {
          this.addon_price.monthly.clicks.quantity = Math.floor((value / this.addon_price.monthly.clicks.min)) * this.addon_price.monthly.clicks.min
        }
        this.addon_price.monthly.clicks.prev = this.addon_price.monthly.clicks.quantity
      }
    }

  }
})
</script>
<style lang="less">
.limit_table {
  tbody {
    display: block;
    overflow-y: auto;
    height: calc(100vh - 24.4rem);
    table-layout: fixed;
  }

  thead, thead tr, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}
</style>
